<!--
 * @Date: 2021-11-05 15:40:40
 * @LastEditors: wxb
 * @LastEditTime: 2021-11-11 11:13:57
 * @Description:
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='7'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>从业人员</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search-info">
        <div class="project-search">
          <div class="project-search-input">
            <el-input class="project-search-field"
                      v-model="searchForm.registerName"
                      placeholder="输入人员姓名搜索..."
                      clearable
                      @keyup.enter.native="searchNotice"></el-input>
            <button type="button"
                    class="project-search-btn"
                    @click="searchNotice">搜索</button>
          </div>
        </div>
        <div class="search-senior">
          <button @click="showSenior=!showSenior">高级搜索 <i class="el-icon-arrow-up"
               v-show="showSenior"></i>
            <i class="el-icon-arrow-down"
               v-show="!showSenior"></i>
          </button>
        </div>
      </div>
      <div class="project-search-senior"
           v-show="showSenior">
        <el-form ref="searchForm"
                 :model="searchForm"
                 label-width="80px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="注册类别">
                <el-select v-model="searchForm.registerType"
                           clearable
                           style="width:100%;"
                           placeholder="请选择注册类别"
                           @change="searchNotice">
                  <el-option v-for="(item,index) in registTypeList"
                             :key="index"
                             :label="item.value"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="注册专业">
                <el-input v-model="searchForm.registerMajor"
                          clearable
                          placeholder="请输入注册专业查询"
                          @keyup.enter.native="searchNotice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="任职机构">
                <el-input v-model="searchForm.officeOrg"
                          clearable
                          placeholder="请输入任职机构查询"
                          @keyup.enter.native="searchNotice"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="注册号">
                <el-input v-model="searchForm.registerCode"
                          clearable
                          placeholder="请输入注册号查询"
                          @keyup.enter.native="searchNotice"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="result-count"
           v-show="canSee">
        为您找到{{total}}名注册人员
      </div>
      <div class="result-list"
           v-show="canSee">
        <el-table :data="tableData"
                  style="width: 100%">
          <template slot="empty">
            <noData tips="未找到相关注册人员信息"
                    v-show="noData"></noData>
          </template>
          <el-table-column prop="registerName"
                           label="姓名"
                           align="center"></el-table-column>
          <el-table-column prop="officeOrg"
                           label="任职机构"
                           align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.orgId"
                         type="text"
                         size="small"
                         @click="handleClickItem(scope.row)"
                         :title="scope.row.officeOrg">{{scope.row.officeOrg}}</el-button>
              <span>{{scope.row.officeOrg}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="registerType"
                           label="注册类别"
                           align="center"></el-table-column>
          <el-table-column prop="registerCode"
                           label="注册号"
                           align="center"></el-table-column>
          <el-table-column prop="registerMajor"
                           label="注册专业"
                           align="center"></el-table-column>
          <!-- <el-table-column prop="validDate"
                           label="有效期"
                           align="center"></el-table-column> -->
        </el-table>
      </div>
      <div class="project-page"
           v-show="!noData && canSee">
        <el-pagination background
                       layout="prev, slot, next"
                       :total="total"
                       @current-change="handleCurrentChange"
                       :current-page.sync="currentPage">
          <span class="current-page">{{currentPage}}</span>
        </el-pagination>
      </div>
    </div>
    <viporlogin bgStr="5"
                :type="viporlogin"
                v-show="!canSee"></viporlogin>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import { getTypeCodeList } from '@/api/dictionary'
import { getRegisterList } from '@/api/registrar'
export default {
  name: 'achievement',
  components: {
    noData,
    viporlogin,
    headerNav,
    footerNav
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  data() {
    return {
      noData: false,
      canSee: true,
      viporlogin: 'login',
      searchForm: {
        start: 0,
        limit: 10
      },
      showSenior: true,
      tableData: [],
      currentPage: 1,
      total: 0,
      registTypeList: []
    }
  },
  methods: {
    authCheck() {
      const authObj = authenticate('registrar', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.searchNotice()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    searchNotice() {
      // 执行搜索
      this.currentPage = 1
      this.getList()
    },
    getList() {
      getRegisterList(this.searchForm).then(data => {
        if (data.ok) {
          this.total = data.cnt
          if (data.cnt > 0) {
            this.noData = false
          } else {
            this.noData = true
          }
          this.tableData = data.lst
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    handleClickItem(row) {
      const id = row.orgId
      const routeData = this.$router.resolve({
        name: 'orgDetail',
        params: {
          id
        }
      })
      window.open(routeData.href, '_blank')
    },
    // 处理分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getList()
    }
  },
  mounted() {
    if (getToken()) {
      getTypeCodeList('zclb').then(res => {
        this.registTypeList = res
      }).catch(err => console.log(err))
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

// 搜索
.search-info {
  margin: 0 auto;
  width: 700px;
  display: flex;

  .search-senior {
    flex: 1;
    margin-left: 20px;
    button {
      width: 100px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
// 搜索框
.project {
  &-search {
    background: #ffffff;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      margin: 0 auto;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 筛选信息
.project {
  &-search-senior {
    background: #ffffff;
    margin: 10px auto;
    padding: 5px 10px;
  }
}

.result-count {
  margin-top: 10px;
  background: #f1f1f1;
  padding: 10px;
}

// 分页
.project-page {
  padding: 10px;
  text-align: right;
}
</style>
