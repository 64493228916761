/*
 * @Author: wxb
 * @Date: 2021-11-10 16:53:01
 * @LastEditors: wxb
 * @LastEditTime: 2021-11-10 16:53:02
 * @Description:
 */

import request from '@/utils/request'

/**
 *
 * @param {*} params
 */
export function getRegisterList(params) {
  return request({
    url: '/api/company/company/getRegisterList',
    method: 'get',
    params
  })
}
